/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 0.7937vmax;
}

.gradient-background {
  background: linear-gradient(260deg, gray, black, black);
  background-size: 200% 200%;
  animation: gradient-animation 6s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 10%;
  }
  100% {
    background-position: 0% 50%;
  }
}
